
import { useLocalization } from '@hooks/localization';
import { NoSsr } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from "react";

export default function DownloadAppButton(props: {
	showAsLink?: boolean,
	style?: React.CSSProperties,
	text?: string,
	size?: "large" | "medium" | "small"
}) {
	const t = useLocalization();
	const [appLink, setAppLink] = useState("https://play.google.com/store/apps/details?id=com.reshopper.reshopper");

	const useStyles = makeStyles((theme) =>
		createStyles({
			mapContainerWhiteBackground: {
				backgroundColor: "#fff",
				paddingTop: "370px",
				[theme.breakpoints.down('xs')]: {
					paddingTop: "270px",
				}
			}
		})
	);

	useEffect(() => {

		function checkForIOSDevice() {
			var userAgent = navigator.userAgent.toLowerCase();

			if (/android/i.test(userAgent)) {
				setAppLink("https://play.google.com/store/apps/details?id=com.reshopper.reshopper");
			}

			if (/mac|ipad|iphone|ipod/i.test(userAgent)) {
				setAppLink("https://itunes.apple.com/app/reshopper/id551998942");
			}

		}
		checkForIOSDevice();

	}, []);


	return <NoSsr>
		{props.showAsLink ?
			<Link
				onClick={() => window.open(appLink)}
				style={{ cursor: "pointer", ...props.style }}>
				{props.text ?
					props.text
					:
					t("LinkText_Download_App")
				}
			</Link>
			:
			<Button
				variant="contained"
				color="secondary"
				title="Hent app"
				size={props.size ?? "large"}
				onClick={() => window.open(appLink)}>
				{props.text ?
					props.text
					:
					t("ButtonText_Download_App")
				}
			</Button>
		}
	</NoSsr>
}